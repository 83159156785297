import React from 'react'
import styles from './Step.module.css'

const ContentContainer = (props) => (
  <div
    className={styles.contentContainer}
    {...props}
  />
)

const Step = ({ color, title, href, subtitle, index, style }) => (
  <section
    style={{
      // height: 200,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 8,
      ...style,
    }}
  >
    <ContentContainer>
      {index != null && (
        <h2 className={styles.stepHeader}>
          {index}
        </h2>
      )}
      <ContentContainer
        style={{
          margin: 22,
        }}
      >
        <a
          href={href}
          className={styles.actionButton}
          style={{ backgroundColor: color }}
        >
          <span
            style={{
              // nudge text to be visually centered
              marginBottom: -2,
            }}
          >
            {title}
          </span>
        </a>
        <h3
          style={{
            whiteSpace: 'nowrap',
            color: 'white',
            margin: '14px 0',
            fontSize: 14,
          }}
        >
          {subtitle}
        </h3>
      </ContentContainer>
    </ContentContainer>
  </section>
)

Step.defaultProps = {
  index: null,
}

export default Step
