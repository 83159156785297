import React from 'react'
import { Router } from "@reach/router"
import { Invitation } from '../components/Invitation'

const FALLBACK_JOIN_CHAT_URL = chatUrlKey => `mzeapp://join_chat/${chatUrlKey}`

export const ChatRedirect = ({ id: chatUrlKey }) => (
  <Invitation
    headerText={"You've been invited to join a group on muze"}
    joinURL={FALLBACK_JOIN_CHAT_URL(chatUrlKey)}
  />
)

// https://reach.tech/router/api/RouteComponent
// TODO: This should go higher up in the app
const Chat = () => (
  <Router>
    <ChatRedirect path="/z/:id" />
  </Router>
)

Chat.defaultProps = {
  path: "/z/:id" 
};

export default Chat

