import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import logo from '../img/Muze-logo.svg'
import waitListlogo from '../img/wait-list-logo.png'
import tiktoklogo from '../img/tiktok.png'
import badge from '../img/Muze-App_Store_Badge.svg'

const Header = ({
  className,
  toggleModal,
  showModalButton,
  showDownloadLink,
}) => {
  return (
    <header className={className}>
      <div className="header--wrapper">
        <div className="header--content">
          <div className="logo--wrapper">
            <Link to="/" className="logo has-default-cursor">
              <img src={logo} alt="Go Home" />
            </Link>
          </div>
          <div className="tiktok-app">
          <a
            href="https://www.tiktok.com/@getmuze"
            className="tiktok has-default-cursor"
          >
            <img src={tiktoklogo} alt="tiktok" />
          </a>
          <a
            href="https://apps.apple.com/us/app/muze-chat/id1565227795"
            className="app-badge has-default-cursor"
          >
            <img src={waitListlogo} alt="Join Waitlist" />
          </a>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  showDownloadLink: true,
  showModalButton: true,
  toggleModal: () => {},
}

export default Header
