import React from 'react'
import styles from './Invitation.module.css'
import { pushBodyClassname } from '../lib/pushClassname'
import LandscapeScene from './LandscapeScene'
import Step from './Step'

const DOWNLOAD_URL = 'https://apps.apple.com/app/id1439964290'

export const Invitation = ({
  headerText,
  joinURL,
}) => {
  React.useEffect(pushBodyClassname('default-cursor'), [])

  return (
    <div className={styles.page}>
      <LandscapeScene>
        <div style={{ zIndex: 2 }} >
          <section className={styles.invitationHeader}>
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                maxWidth: 375,
                width: '100%',
                justifyContent: 'space-between',
                padding: 25,
              }}
            >
              <p className={styles.infoText}>
                {headerText}
              </p>
            </div>
          </section>

          <Step
            index={1}
            title="Download Muze"
            href={DOWNLOAD_URL}
            color="#9D8EF5"
            subtitle="*only on Apple devices"
          />
          <Step
            index={2}
            title="Tap Here to Join"
            href={joinURL}
            color="#E78EF5"
            subtitle="*if you’ve made an account"
          />
        </div>
      </LandscapeScene>
    </div>
  )
}
