export function pushClassname(classname, selector) {
  return () => {
    const node = document.querySelector(selector)
    node.classList.toggle(classname, true)
    return () => {
      node.classList.toggle(classname, false)
    }
  }
}

// When used as a React effect, adds a classname to the document's body on component mount, then removes it on unmount.
//
//     React.useEffect(pushBodyClassname('hide-everything'), [])
export function pushBodyClassname(classname) {
  return pushClassname(classname, 'body')
}

export default pushClassname

