import React from 'react'
import { pushBodyClassname } from '../lib/pushClassname'
import Header from '../components/header'
import styles from './LandscapeScene.module.css'

const FooterVideo = () => (
  <div
    style={{
      zIndex: -999,
      overflow: 'hidden',
      width: '100%',

      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',

      // nudge video up to be closer to bottom of text
      marginTop: -100,
    }}
  >
    <div
      style={{
        position: 'relative',
        width: '100%',
        // this number must match the term in the `calc` below
        minWidth: 700,
        // aspect ratio (height / width) + some bleed
        paddingBottom: 'calc(max(64.27%, 700px * 0.6427))',
        height: 0,

        zIndex: 1,
      }}
    >
      <iframe
        title="rad"
        src="https://player.vimeo.com/video/445004881?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0"
        allow="autoplay; fullscreen"
        allowFullScreen={false}
        frameBorder="0"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      />
      {/* Put a gradient over the video to fade it into the background */}
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundImage: 'linear-gradient(to bottom, rgba(40, 109, 206, 1), rgba(40, 109, 206, 0) 30%)',
        }}
      />
    </div>
  </div>
)

const LandscapeScene = ({ children }) => {
  React.useEffect(pushBodyClassname(styles.body), [])
  return (
    <>
      <Header
        showModalButton={false}
        showDownloadLink={false}
      />
      {children}
      <FooterVideo />
    </>
  )
}

export default LandscapeScene
